import React, { useRef } from 'react';

const FileUpload = ({ onFileSelect }) => {
  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onFileSelect(file);
    }
  };

  return (
    <div className="file-upload">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".xlsx,.xls"
        style={{ display: 'none' }}
      />
      <button onClick={() => fileInputRef.current.click()}>
        Select Excel File
      </button>
    </div>
  );
};

export default FileUpload;