import React, { useState } from 'react';
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style'; 
import FileUpload from './FileUpload';

const ExcelProcessor = () => {
  const [processedFile, setProcessedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [counter, setCounter] = useState(53801);


  const sortByLength = (arr) => {
    return arr.sort((a, b) => b.length - a.length);
  };

  const processExcelFile = async (file) => {
    try {
      setLoading(true);
      setError(null);
      let counter = 53801;


      const reader = new FileReader();
      
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array', cellStyles: true }); 

        /* GET 3 types of uniqueu donation anywhere, anywherecolor, specific */
          const colorsheet = workbook.Sheets['station'];
          const anywheres = [];
          const anywherecolors = [];
          const specifics = [];

          const colorsheetrange = XLSX.utils.decode_range(colorsheet['!ref']);
          for (let row3 = 1; row3 <= colorsheetrange.e.r; row3++) {
            const cellAddress3 = XLSX.utils.encode_cell({ r: row3, c: 0 });
            const cell3 = colorsheet[cellAddress3];
            if (cell3) {
              if (!anywheres.includes(cell3.v)) {
                anywheres.push(cell3.v); 
              }
            }

            const cellAddress31 = XLSX.utils.encode_cell({ r: row3, c: 1 });
            const cell31 = colorsheet[cellAddress31];
            if (cell31) { 
              if (!anywherecolors.includes(cell31.v)) {
                anywherecolors.push(cell31.v); 
              }
            }

            const cellAddress32 = XLSX.utils.encode_cell({ r: row3, c: 2 });
            const cell32 = colorsheet[cellAddress32];
            if (cell32) {
              if (!specifics.includes(cell32.v)) {
                specifics.push(cell32.v); 
              }
            }  
          }
 
        // Get Sheet2 (name sheet) data
          const worksheet2 = workbook.Sheets['removetext'];
          const suffixPhrases = [];
          const prefixPhrases = [];
          const anyPhrases = [];

          const range2 = XLSX.utils.decode_range(worksheet2['!ref']);
          for (let row2 = 1; row2 <= range2.e.r; row2++) {
            const cellAddress2 = XLSX.utils.encode_cell({ r: row2, c: 0 });
            const cell2 = worksheet2[cellAddress2];
            if (cell2) {
              suffixPhrases.push(cell2.v);
            }

            const cellAddress21 = XLSX.utils.encode_cell({ r: row2, c: 1 });
            const cell21 = worksheet2[cellAddress21];
            if (cell21) {
              prefixPhrases.push(cell21.v);
            }

            const cellAddress22 = XLSX.utils.encode_cell({ r: row2, c: 2 });
            const cell22 = worksheet2[cellAddress22];
            if (cell22) {
              anyPhrases.push(cell22.v);
            } 

          }

        const suffixArray = sortByLength(suffixPhrases);
        const prefixArray = sortByLength(prefixPhrases);
        const anyPositionArray = sortByLength(anyPhrases);

        // Process Sheet1
        const worksheet1 = workbook.Sheets['rowdata'];
        const range = XLSX.utils.decode_range(worksheet1['!ref']);
        const updatedRecords = [];

        
        for (let row = 0; row < 2; row++) { 
          const vCellAddress = XLSX.utils.encode_cell({ r: row, c: 21 });  
          const existingCell = worksheet1[vCellAddress];
          const existingStyle = existingCell?.s || {}; 
          worksheet1[vCellAddress] = {
            t: 's',
            v: worksheet1[vCellAddress]?.v || '',
            s: {
              ...existingStyle 
            }
          };
        }

        for (let row = 2; row <= range.e.r; row++) {

          /* Check for color change */
          const uCellAddress = XLSX.utils.encode_cell({ r: row, c: 20 });
          const vCellAddress = XLSX.utils.encode_cell({ r: row, c: 21 }); 
          const uCell = worksheet1[uCellAddress];
          const uValue = uCell ? uCell.v : undefined; 
 
          if (anywheres.includes(uValue)) {
            worksheet1[vCellAddress] = {
              t: 's',
              v: counter.toString()
            };
            counter = counter >= 54800 ? 53801 : counter + 1; 
          }
          if (anywherecolors.includes(uValue)) {
            worksheet1[vCellAddress] = {
              t: 's',
              v: worksheet1[vCellAddress]?.v || '',
              s: {
                fill: {
                  patternType: 'solid',
                  fgColor: {rgb: 'FFFF00'},
                  bgColor: {rgb: 'FFFF00'}
                }
              }
            };
          }
          if (specifics.includes(uValue)) {
            worksheet1[vCellAddress] = {
              t: 's',
              v: worksheet1[vCellAddress]?.v || '',
              s: {
                fill: {
                  patternType: 'solid',
                  fgColor: {rgb: '800080'},
                  bgColor: {rgb: '800080'}
                }
              }
            };
          }
          
            
          
          const wCellAddress = XLSX.utils.encode_cell({ r: row, c: 22 });
          const wCell = worksheet1[wCellAddress];
          const wValue = wCell ? wCell.v : undefined;

          if (wValue) {
            let updatedValue = wValue; 
            // Remove suffix
            for (const suffix of suffixArray) {
              if (updatedValue.endsWith(suffix)) {
                updatedValue = updatedValue.slice(0, -suffix.length).trim();
                break;
              }
            }

            // Remove prefix
            for (const prefix of prefixArray) {
              if (updatedValue.startsWith(prefix)) {
                updatedValue = updatedValue.slice(prefix.length).trim();
                break;
              }
            }

            // Remove any position string
            for (const str of anyPositionArray) {
              if (updatedValue.includes(str)) {
                updatedValue = updatedValue.replace(str, '').trim();
                break;
              }
            }

            if (updatedValue !== wValue) {
              updatedRecords.push({
                'w': wValue,
                'x': wValue.replace(updatedValue, '').trim(),
                'y': updatedValue
              }); 
            
              worksheet1[XLSX.utils.encode_cell({ r: row, c: 23 })] = { 
                t: 's', 
                v: wValue.replace(updatedValue, '').trim() 
              };
              
              worksheet1[XLSX.utils.encode_cell({ r: row, c: 24 })] = { 
                t: 's', 
                v: updatedValue 
              };
            } 
             
          }
        }

        // Write the modified workbook
        const newWorkbook = XLSX.write(workbook, { 
          bookType: 'xlsx', 
          type: 'binary',
          cellStyles: true,   
          compression: true 
        });
  
        const blob = new Blob([s2ab(newWorkbook)], { 
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });
        setProcessedFile(blob);
        setLoading(false);
      }; 
      reader.readAsArrayBuffer(file);
    } catch (err) {
      setError('Error processing file: ' + err.message);
      setLoading(false);
    }
  };

  const downloadFile = () => {
    if (processedFile) {
      const url = window.URL.createObjectURL(processedFile);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'processed_excel.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  return (
    <div className="excel-processor">
      <FileUpload onFileSelect={processExcelFile} />
      
      {loading && <div>Processing file...</div>}
      {error && <div className="error">{error}</div>}
      
      {processedFile && (
        <button onClick={downloadFile}>
          Download Processed File
        </button>
      )}
    </div>
  );
};

export default ExcelProcessor;